import { http } from '@/http/axios.js'
// 停车位列表
export function getListAPI(params) {
  return http({
    url: '/company/parkingSpace/list',
    method: 'get',
    params
  })
}
// 充电站表
export function getStationListAPI(params) {
  return http({
    url: '/company/chargingStation/list',
    method: 'get',
    params
  })
}
// 详情
export function getDetailAPI(id) {
  return http({
    url: '/company/parkingSpace/detail',
    method: 'get',
    params: { id }
  })
}
// add
export function addParkSpaceAPI(data) {
  return http({
    url: '/company/parkingSpace/add',
    method: 'post',
    data
  })
}
// edit
export function editParkSpaceAPI(data) {
  return http({
    url: '/company/parkingSpace/edit',
    method: 'post',
    data
  })
}
// del
export function delParkSpaceAPI(id) {
  return http({
    url: '/company/parkingSpace/delete',
    method: 'post',
    data: { id }
  })
}
