<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="充电站" prop="station_id" :rules="rules">
        <SelectStation v-model="formData.station_id"></SelectStation>
      </el-form-item>
      <el-form-item label="停车位编号" prop="number" :rules="rules">
        <el-input style="width: 400px" v-model="formData.number" size="small" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="充电枪" v-if="formData.station_id" prop="pile_gun_id" :rules="rules">
        <SelectPileGun ref="pileGun" v-model="formData.pile_gun_id" :station_id="formData.station_id"></SelectPileGun>
      </el-form-item>
      <el-form-item label="地锁" v-if="formData.station_id" prop="lock_id">
        <SelectLock ref="lock" v-model="formData.lock_id" :station_id="formData.station_id"></SelectLock>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDetailAPI, addParkSpaceAPI, editParkSpaceAPI } from './api'
import SelectStation from '@/views/components/select-station.vue'
import SelectPileGun from '@/views/components/select-gun.vue'
import SelectLock from '@/views/components/select-lock.vue'
export default {
  name: 'AddOrEdit',
  components: { SelectStation, SelectPileGun, SelectLock },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        station_id: '',
        number: '',
        name: '',
        description: '',
        status: '',
        pile_gun_id: '',
        lock_id: ''
      },
      station_list: [],
      pile_list: [],
      lock_list: []
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },
  watch: {
    'formData.station_id': {
      handler(newV, oldV) {
        if (newV) {
          this.$nextTick(() => {
            this.$refs.pileGun.getList()
            this.$refs.lock.getList()
          })
        }
      }
    }
  },
  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editParkSpaceAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addParkSpaceAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
